import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Image, Container } from "react-bootstrap";
import inteApis from "../../services/IntegrationService";
import arrowLeft from "../../assets/images/arrow-left.svg";
import OnlySupperAdmin from "../../components/UI/OnlySupperAdmin/OnlySupperAdmin";
import CanvasIntegration from "./CanvasIntegration";
import BlackboardIntegration from "./BlackboardIntegration";
import Desire2learnIntegration from "./Desire2learnIntegration";
import MoodleIntegration from "./MoodleIntegration";

export const Integrations = () => {
  const { t } = useTranslation("translation");
  const history = useHistory();
  const [integrations, setIntegrations] = useState({
    canvas: null,
    blackboard: null,
    desire2learn: null,
    moodle: null,
  });

  const fetchIntegrations = async () => {
    const { status, data } = await inteApis.getIntegrations();
    if (status === 200) {
      // setIntegrations(data?.data?.integrations || []);
      let _integrations: any = {};
      data?.data?.integrations?.forEach((item: any) => {
        _integrations[item.provider] = item;
      });
      setIntegrations(_integrations);
    }
  };

  useEffect(() => {
    fetchIntegrations();
  }, []);

  return (
    <OnlySupperAdmin>
      <div className="page-mid-wraper integration">
        <button
          className="btn p-0 btn-back mt-2"
          onClick={() => {
            history.goBack();
          }}
        >
          <Image src={arrowLeft} /> {t("Return to previous page")}
        </button>

        <div className="tw-grid tw-grid-cols-3 tw-gap-4 md:max-lg:tw-grid-cols-2 max-md:tw-grid-cols-1">
          <CanvasIntegration
            data={integrations.canvas}
          />
          <BlackboardIntegration
            data={integrations?.blackboard}
          />
          <Desire2learnIntegration
            data={integrations?.desire2learn}
          />
          <MoodleIntegration
              data={integrations?.moodle}
          />
        </div>
      </div>
    </OnlySupperAdmin>
  );
};

export default Integrations;
