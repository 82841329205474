import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import {Button, Image, Modal, ModalFooter} from "react-bootstrap";
import inteApis from "../../services/IntegrationService";
import lmsLogo from "../../assets/images/desire2learn.svg";
import closeimg from "../../assets/images/close-blk.svg";
import tick from "../../assets/images/tick.svg";
import check from "../../assets/images/check.svg";
import "./integration.scss";

const Desire2learnIntegration = (props: any) => {
  const {t} = useTranslation("translation");
  const [showsEditModal, setShowsEditModal] = useState(false);
  const [popUpState, setPopUpState] = useState("");
  const [modalFormData, setModalFormData] = useState({
    client_id: "",
    client_secret: "",
    base_url: "",
    _id: null,
  });

  useEffect(() => {
    if (props?.data) {
      setModalFormData((prevState) => ({
        ...prevState,
        client_id: props?.data?.params?.client_id,
        client_secret: props?.data?.params?.client_secret,
        base_url: props?.data?.params?.base_url,
        _id: props?.data?._id,
      }));
    }
  }, [props]);


  const inputChangeHandler = (e: any) => {
    if (e?.target) {
      setModalFormData((prevState) => ({
        ...prevState,
        [e.target.name]: e?.target?.value,
      }));
    }
  };

  const clickIntegration = () => {
    setShowsEditModal(true);
  };

  const renderModalBody = () => {
    switch (popUpState) {
      case "loading":
        return (
            <div className="d-flex flex-column justify-content-center align-items-center">
              <div className="spinner-border spinner-border-lg text-primary"></div>
              <div className="pt-1">{t("Connecting... Please wait")}</div>
            </div>
        );

      case "success":
        return (
            <div className="d-flex flex-column justify-content-center align-items-center">
              <Image src={tick}/>
              <div className="pt-1">{t("Connected Successfully")}</div>
            </div>
        );

      default:
        return (
            <>
              <div>
                <h3>{t("Provider")}</h3>
                <input
                    type="text"
                    className="form-control he-35 radius-2"
                    placeholder={t("Provider")}
                    name="provider"
                    disabled
                    value="D2L Brightspace"
                />
              </div>
              <div>
                <h3>{t("D2L Brightspace LMS URL")}</h3>
                <input
                    type="text"
                    className="form-control he-35 radius-2"
                    placeholder={t("Enter D2L Brightspace LMS URL")}
                    name="base_url"
                    onChange={inputChangeHandler}
                    value={modalFormData.base_url}
                />
              </div>
              <div>
                <h3>{t("Client ID")}</h3>
                <input
                    type="text"
                    className="form-control he-35 radius-2"
                    placeholder={t("Enter client ID")}
                    name="client_id"
                    onChange={inputChangeHandler}
                    value={modalFormData.client_id}
                />
              </div>
              <div>
                <h3>{t("Client Secret")}</h3>
                <input
                    type="text"
                    className="form-control he-35 radius-2"
                    placeholder={t("Enter client Secret")}
                    name="client_secret"
                    onChange={inputChangeHandler}
                    value={modalFormData.client_secret}
                />
              </div>
            </>
        );
    }
  };

  const connectClick = async () => {
    setPopUpState("loading");
    let res: any = null;
    if (modalFormData._id !== null) {
      res = await inteApis.updateIntegration(modalFormData._id, {
        params: {
          base_url: modalFormData.base_url,
          client_id: modalFormData.client_id,
          client_secret: modalFormData.client_secret,
        },
      });
    } else {
      res = await inteApis.createIntegration({
        provider: "desire2learn",
        params: {
          base_url: modalFormData.base_url,
          client_id: modalFormData.client_id,
          client_secret: modalFormData.client_secret,
        },
      });
    }

    if (res.status === 200) {
      if (res?.data?.data?.integration?._id)
          setModalFormData((prevState) => ({
            ...prevState,
            _id: res?.data?.data?.integration?._id,
          }));
        setPopUpState("success");
    }
  };

  const renderEditModal = () => {
    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            show={showsEditModal}
            onHide={() => {
            }}
            centered
        >
          <ModalHeader className="justify-content-start border-bottom py-2 align-items-center">
            <ModalTitle className="container-fluid d-inline-flex justify-content-between align-items-center p-0">
              <div>{t("Connect to D2L Brightspace")}</div>
              <Button
                  onClick={() => {
                    setShowsEditModal(false);
                    setPopUpState("");
                  }}
              >
                <Image src={closeimg}/>
              </Button>
            </ModalTitle>
          </ModalHeader>
          <ModalBody className="integration-modal-body">
            {renderModalBody()}
          </ModalBody>

          {popUpState === "" && (
              <ModalFooter>
                <Button
                    variant="outline-primary"
                    onClick={() => {
                      setShowsEditModal(false);
                      setPopUpState("");
                    }}
                >
                  {t("Cancel")}
                </Button>
                <Button
                    variant="primary"
                    disabled={!modalFormData.base_url || !modalFormData.client_id || !modalFormData.client_secret}
                    onClick={() => connectClick()}
                >
                  {t("Connect")}
                </Button>
              </ModalFooter>
          )}
        </Modal>
    );
  };

  return (
      <>
        {renderEditModal()}
        <div
            role="button"
            className="integration-item"
            onClick={(e: any) => {
              e.stopPropagation();
              modalFormData._id !== null && clickIntegration();
            }}
        >
          <div className="container-fluid tw-flex align-items-center p-0 tw-gap-3">
            <div style={{ backgroundImage: `url(${lmsLogo})` }} className="tw-flex-1 tw-h-full tw-bg-left tw-bg-no-repeat tw-bg-contain" />
            {modalFormData._id !== null ? (
                <button
                    className="btn btn-success tw-ml-auto"
                    onClick={(e) => clickIntegration()}
                >
                  <Image src={check} className="pr-1"/>
                  {t("Connected")}
                </button>
            ) : (
                <button
                    className="btn btn-primary tw-ml-auto"
                    onClick={() => clickIntegration()}
                >
                  {t("Connect")}
                </button>
            )}
          </div>
          <div>
            <h4>{t("D2L Brightspace")}</h4>
          </div>
          <div>
            {t(
                "Create and deliver personalized learning experiences at scale, with powerful tools, customizable content and actionable data."
            )}
          </div>
        </div>
      </>
  );
};

export default Desire2learnIntegration;
