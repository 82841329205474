import { useEffect, useRef, useState } from "react";
import { Button, Image, Modal, ModalFooter, Spinner } from "react-bootstrap";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import { useDispatch } from "react-redux";
import Select from "react-select";
import inteApis from "../../../../services/IntegrationService";
import downarrowblk from "../../../../assets/images/chevron-down-blk.svg";
import closeimg from "../../../../assets/images/close-blk.svg";
import PermissionWrapper from "../../../../components/UI/PermissionWrapper/PermissionWrapper";
import { linkACourseToClass } from "../../../../store/knowledge/actions";
import {
  connectToCanvas,
  connectToOauth2Lms,
  getIntegrationCurrentCourses,
} from "../../../../store/quizzes/actions";

import { useTranslation } from "react-i18next";

export const lmsListData = [
  {
    value: "BlackboardLearn",
    filterValue: "blackboard",
    label: "Blackboard",
    isIntegration: true,
  },
  {
    value: "canvas",
    filterValue: "canvas",
    label: "Canvas",
    isIntegration: true,
  },
  {
    value: "desire2learn",
    label: "Desire2learn",
    filterValue: "desire2learn",
    isIntegration: true,
  },
  {
    value: "moodle",
    label: "Moodle",
    filterValue: "moodle",
    isIntegration: true,
  },
  {
    value: "googleclassroom",
    filterValue: "googleclassroom",
    label: "Google Classroom",
  },
  {
    value: "microsoft365",
    filterValue: "microsoft365",
    label: "Microsoft 365",
  },
  {
    value: "Wizlearn",
    filterValue: "Wizlearn",
    label: "Wizlearn",
  },
];

const isIntegration = (provider: any) => {
  return provider.isIntegration;
};

const LinkCourseToAgent = (props: any) => {
  const { t } = useTranslation("translation");
  const timerRef = useRef<NodeJS.Timeout>();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [linking, setLinking] = useState(false);
  const [courses, setCourses] = useState([]);
  const [integrations, setIntegrations] = useState([]);
  const [filteredLMSList, setFilteredLMSList] = useState([]);
  const [currentCourse, setCurrentCourse] = useState({
    id: "",
    label: "",
    name: "",
    value: "",
    course_code: "",
  });
  const defaultProvider = {
    value: "",
    label: "",
  };
  const [selectedButtonTarget, setSelectedButtonTarget] = useState({
    courseId: "",
    provider: defaultProvider,
  });


  useEffect(() => {
    if (!props.show) {
      return;
    }

    if (props.courseLMS) {
      const course = filteredLMSList.find(
        (d: any) => d.value === props.courseLMS.provider
      );
      setSelectedButtonTarget({
        courseId: props.courseLMS?.course_id,
        provider: course
          ? course
          : defaultProvider,
      });
    } else {
      setSelectedButtonTarget({
        courseId: "",
        provider: defaultProvider,
      });
    }
  }, [props.show, filteredLMSList]);


  const fetchIntegrations = async () => {
    const { status, data } = await inteApis.getIntegrations();
    if (status === 200) {
      setIntegrations(data?.data?.integrations);
    }
  };

  useEffect(() => {
    fetchIntegrations();
  }, []);

  useEffect(() => {
    if (!Array.isArray(integrations)) return;

    setFilteredLMSList(lmsListData.map((t) => {
      if (t.isIntegration && !integrations.find((integration) => integration.provider === t.filterValue)) {
        return {
          ...t,
          isIntegration: false,
        };
      }

      return t;
    }))
  }, [integrations]);

  const linkCourseHandler = async () => {
    if (
      !isIntegration(selectedButtonTarget.provider) &&
      (!selectedButtonTarget.courseId || !selectedButtonTarget.provider)
    ) {
      return;
    }
    if (isIntegration(selectedButtonTarget.provider) && !currentCourse.id)
      return;

    let courseId = "";
    if (!isIntegration(selectedButtonTarget.provider))
      courseId = selectedButtonTarget.courseId;
    else if (currentCourse) {
      if (selectedButtonTarget.provider.label === "Canvas" || selectedButtonTarget.provider.label === "Blackboard")
        courseId = currentCourse.course_code;
      else
        courseId = currentCourse.id;
    }

    try {
      setLinking(true);
      await dispatch(
        linkACourseToClass(
          {
            course_id: courseId,
            provider: selectedButtonTarget.provider.value,
          },
          props.id
        )
      );
      props.confirmHandler();
      setLinking(false);
    } catch (error: any) {
      setLinking(false);
    }
  };

  const inputChangeHandler = (e: any) => {
    setSelectedButtonTarget({
      ...selectedButtonTarget,
      [e.target.name]: e.target.value,
    });
  };

  const handleIntegrations = async (integration: string) => {
    connectToIntegrationHandler(integration);
  };

  const connectToIntegrationHandler = async (
    integration: string
  ) => {
    try {
      setLoading(true);
      let response: any;
      if (integration === "canvas")
        response = await dispatch(connectToCanvas());
      else response = await dispatch(connectToOauth2Lms(integration, true));

      response.auth_url && window.open(response.auth_url, "_blank");

      if (
        response &&
        (
            !response[`${integration}_integration`]
          // (integration === "canvas" && !response.canvas_integration) ||
          // (integration === "blackboard" && !response.blackboard_integration)
        ) &&
        !timerRef.current
      ) {
        timerRef.current = setInterval(async () => {
          let connectToTimerResponse: any;
          if (integration === "canvas") {
            connectToTimerResponse = await dispatch(connectToCanvas());
            if (connectToTimerResponse.canvas_integration) {
              getIntegrationCurrentCoursesHandler(integration);
            }
          } else {
            connectToTimerResponse = await dispatch(connectToOauth2Lms(integration));
            if (connectToTimerResponse[`${integration}_integration`]) {
              getIntegrationCurrentCoursesHandler(integration);
            }
          }
        }, 5 * 1000);
      } else {
        getIntegrationCurrentCoursesHandler(integration);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getIntegrationCurrentCoursesHandler = async (
    integration: string
  ) => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }

    try {
      setLoading(true);
      const getIntegrationCurrentCoursesResponse: any = await dispatch(
        getIntegrationCurrentCourses(integration)
      );
      setCourses([
        ...getIntegrationCurrentCoursesResponse.courses.map((d: any) => {
          return { ...d, label: d.name, value: d.id };
        }),
      ]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // setCourses([]);
    }
  };

  useEffect(() => {
    setCourses([]);
    if (
        isIntegration(selectedButtonTarget.provider)
    ) {
      handleIntegrations(selectedButtonTarget.provider.label.toLowerCase());
    }
  }, [selectedButtonTarget.provider]);

  return (
    <Modal
      show={props.show}
      size={props.size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal-400"
      onHide={props.onHide}
    >
      <ModalHeader className="justify-content-start border-bottom py-2 align-items-center">
        <ModalTitle
          className="h5 py-1 font-600"
          id="contained-modal-title-vcenter"
        >
          {t("Link Course")}
        </ModalTitle>
        <Button onClick={props.onHide} className="ml-auto">
          <Image src={closeimg} alt="" />
        </Button>
      </ModalHeader>
      <ModalBody>
        <div className="mb-12">
          <h3 className="mb-1">{t("Choose LMS")}</h3>
          <Select
            isSearchable={false}
            className="select-target radius-2"
            classNamePrefix="select-target-prefix"
            components={{
              //DropdownIndicator: () => null,
              DropdownIndicator: (props: any) => (
                <img src={downarrowblk} alt={t("dropdown arrow")} />
              ),
              IndicatorSeparator: () => null,
            }}
            defaultValue={selectedButtonTarget.provider || null}
            onChange={(e: any) => {
              setSelectedButtonTarget({
                ...selectedButtonTarget,
                provider: e,
              });
            }}
            placeholder={t("Choose LMS")}
            getOptionLabel={(option: any) => option["label"]}
            getOptionValue={(option: any) => option["value"]}
            options={filteredLMSList}
            value={selectedButtonTarget.provider || null}
          />
        </div>

        <div className="mb-12">
          {isIntegration(selectedButtonTarget.provider) && loading && (
            <div className="tw-flex tw-gap-1 tw-items-center">
              <Spinner animation="border" size="sm" />
              <>Checking for login...</>
            </div>
          )}

          {((isIntegration(selectedButtonTarget.provider) && !loading) ||
            !isIntegration(selectedButtonTarget.provider)) && (
            <h3 className="mb-1">
              {isIntegration(selectedButtonTarget.provider)
                ? t("Courses")
                : t("Course ID")}
            </h3>
          )}

          {isIntegration(selectedButtonTarget.provider) &&
            !loading &&
            courses.length === 0 && <>No courses found...</>}

          {!isIntegration(selectedButtonTarget.provider) && (
            <input
              type="text"
              className="form-control he-35 radius-2"
              placeholder={t("Please enter course ID")}
              name="courseId"
              value={selectedButtonTarget.courseId || ""}
              onChange={inputChangeHandler}
            />
          )}

          {isIntegration(selectedButtonTarget.provider) &&
            courses.length > 0 && (
              <Select
                options={courses}
                isSearchable
                onChange={(e: any) => {
                  setCurrentCourse(e);
                }}
              />
            )}
        </div>
      </ModalBody>
      <ModalFooter>
        <PermissionWrapper>
          <button
            disabled={loading}
            className="btn-outline-primary btn font-600 py-1"
            onClick={props.onHide}
          >
            {t("Cancel")}
          </button>
          <button
            className="btn btn-primary ml-3 font-600 py-1"
            onClick={linkCourseHandler}
            disabled={linking}
          >
            {t("Link Course")}
          </button>
        </PermissionWrapper>
      </ModalFooter>
    </Modal>
  );
};

export default LinkCourseToAgent;
